.viewSwitcherContainer {
  .customSwitch {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    margin-top: -25px;
    padding-top: 0;
  }

  .custom-switch {
    .custom-control-label {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
