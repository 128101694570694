.transparent-button {
  background-color: transparent;

  &.highlight-icon svg {
    color: red !important;
  }

  svg {
    &:hover {
      cursor: pointer;
    }
  }
}

.container {
  .row {
    .droneControllerButtons {
      width: 100%;
      height: 10%;

      .buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .flightControl {
          display: flex;
        }
      }
    }
  }
}

.flightControlContainer {
  height: 90%;
}
