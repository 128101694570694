.op-controls {
  display: none;
}

i.op-con.op-warning::before {
  content: "";
}

.op-message-container {
  display: none;
}

.op-progressbar {
  display: none !important;
}
