.analysis-toolbar {
  .card-header {
    .btn {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  
  &>.card-body {
    flex: 1;
    margin-top: 0 !important;
    margin-bottom: 0.5rem;

    .userCounter {
      color: whitesmoke;
      margin-bottom: 0.5rem;
    }
  }

  .card-footer {
    .row { padding: 15px}
    display :flex;
    align-items: center;
    justify-content: space-around;

    .btn {
      display: inline;
    }
  }
}
