.projectCard {
  .projectName {
    white-space: nowrap; // replace with ellipsis mixin
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .projectAddress {
    @supports (-webkit-line-clamp: 2) {
      min-height: 2.5rem;
    }
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    a {
      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .card-label {
    display: inline-block;
    width: auto;
    white-space: nowrap;
  }

  .isDroneActive {
    width: 0.6rem;
    height: 0.6rem;
    background-color: red;
    border-radius: 100%;
    box-shadow: 0px 0px 5px #b62c2c;
  }

  .entryLine {
    display: flex;
    align-items: baseline;
  }
}
