.flightPlanView {
  flex: 1;
  display: flex;
  overflow: hidden;

  .flightPlanContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    overflow: hidden;

    margin: 0 30px 30px 130px;

    .title-bar {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        margin: 0;
        margin-right: 2rem;
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .drawPathButton {
          margin-right: 0.5rem;
        }

        .drawAutoPathButton {
          margin-right: 0.5em;
        }

        .clearPathButton {
          margin-right: 4em;
        }
      }

      button {
        white-space: nowrap;

        i {
          margin-top: -4px;
        }
      }
    }
  }
}

.toast-custom-class {
  margin-top: 4em;
  margin-right: 2em;
}

.flashingButton {
  animation: flashingAnimation 1s infinite alternate;
}

@keyframes flashingAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.75;
  }
}
