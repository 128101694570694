.commentSection {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;

  .comments {
    ul {
      list-style-type: none;
      padding-left: 0;
    }
  }
}
