.droneHeightContainer {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;

  .heightRuler {
    height: 200px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .daaveSideIcon {
    display: flex;
    align-items: center;

    .containerIcon {
      display: flex;
      flex-direction: column;
      align-items: center;

      .arrowMoveUp {
        padding-left: 15px;
      }

      .arrowMoveDown {
        padding-left: 15px;
        margin-top: -25px;
      }

      .pointer {
        display: flex;
        align-items: center;
        margin-left: -10px;

        .pointerArrow {
          padding-bottom: 24px;
          margin-right: -10px;
        }
        .droneImg {
          width: 150px;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
    }
  }
}
