.usersManagerContainer {
  overflow-y: auto;
  overflow-x: hidden;

  .table-responsive {
    overflow: hidden;
  }
}

.historyMeetingRow {
  &:hover {
    background-color: hsla(0, 0%, 100%, 0.1) !important ;
  }

  .historyMeetingName {
    &:hover {
      cursor: pointer;
    }
  }
}
