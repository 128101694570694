.usernameContainer {
  display: flex;
  align-items: center;
  .username {
    color: white;
    padding-left: 3rem;
  }
}

.navbar-minimize.d-inline {
  button {
    padding-bottom: 0.75rem;
  }
}
