.usersManagerContainer {
  overflow-y: auto;
  overflow-x: hidden;
}

.customTable {
  min-width: 300px;
  table-layout: fixed;
  .customTable th.name,
  .customTable td.name {
    min-width: 100px;
  }
  .customTable th.email,
  .customTable td.email {
    min-width: 100px;
  }
  .role {
    width: 120px;
  }
  .actions {
    width: 130px;
    @media (max-width: 425px) {
      width: 42px;
    }
  }
}

.customTable th:nth-child(1),
.customTable td:nth-child(1) {
  white-space: normal;
  min-height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: 100%;
}

.customTable th,
.customTable td {
  text-overflow: ellipsis;
  overflow: hidden;
  .btn {
    margin-top: 4px;
    margin-left: 4px;
  }
}
