.slamContainer {
  flex: 1;
  position: relative;
  overflow: hidden;

  .lil-gui.root {
    > .title {
      height: 1.875rem; // button size;
      line-height: 1.875rem; 
      padding-left: 8px;
      pointer-events: none;
      &::before {
        display: none;
      }
    }
  }

  .btn.btn-icon {
    position: absolute;
    top: 0px;
    padding: 0;
    margin: 0;
    background-color: #111;
    z-index: 10;

    &:hover, &:active, &.active, &:focus {
      background-color: #111 !important;
    }

    &.cheatsheet-button {
      left: 0;
      border-radius: 0 0 6px 0;
    }

    &.view-controls-button {
      right: 0;
      border-radius: 0  0 0 6px;
    }
  }

  .view-controls-container {
    position: absolute;
    top: 0;
    right: 0;
  }

  .cheatsheet {
    position: absolute;
    top: 0;
    left: 0;

    // copied from lil-gui for consistency
    color: #ebebeb;
    background-color: #1f1f1f;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Arial,sans-serif; 
    font-size: 11px;
    line-height: 25px;

    .title {
      height: 1.875rem; // button size;
      line-height: 1.875rem; 
      margin-left: 1.875rem;
      padding: 0 20px 0 4px;
      background-color: #111;

    }
    .content {
      padding: 10px;
    }
  }
}
