$buildingPlanCardImageHeight: 12rem;

.buildingPlanCard {
  .truncateLine {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
  }

  .card.noData {
    border: 5px dashed #29253d;
    background-color: transparent;

    .noData {
      height: $buildingPlanCardImageHeight;
    }
  }

  img {
    height: $buildingPlanCardImageHeight;
  }

  .imagePlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;

    height: $buildingPlanCardImageHeight;

    i {
      font-size: calc($buildingPlanCardImageHeight / 3);
    }
  }

  .indicator {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 100%;

    &.active {
      background-color: green;
      box-shadow: 0 0 5px #3ac82a;
    }

    &.inactive {
      background-color: red;
      box-shadow: 0 0 5px #b62c2c;
    }
  }
}
