$button-size: 1.6rem;

.commentCard {
  cursor: pointer;

  .card-body {
    background-color: #0d1a26;

    &.selected {
      background-color: #1d8cf8;
    }

    &.commentBody {
      display: flex;
      color: whitesmoke;

      .profileIcon {
        align-self: center;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        color: black;
        font-size: 1.5rem;
      }

      .comment-text {
        flex: 1;
        padding-left: 15px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
      }

      .buttons {
        margin: -14px -14px -14px 15px;
        width: $button-size;

        .btn {
          margin: 0;
          pointer-events: none;

          &.clickable {
            pointer-events: all;
          }
        }

        .btn.btn-icon.btn-sm{
          height: $button-size;
          min-width: $button-size;
          width: $button-size;

          &.thick i {
            font-weight: bold;
          }
        }
      }
    }
  }

  .card-body.commentBody.selected .comment-text {
    -webkit-line-clamp: 8; /* start showing ellipsis when 3rd line is reached */
  }
}
