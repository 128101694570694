.analysisView {
  flex: 1;
  display: flex;
  overflow: hidden;

  .flightContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    overflow: hidden;

    margin: 0 0 0 115px;

    .title-bar {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 15px;

      h4 {
        margin: 0;
      }
    }
  }

  .analysis-toolbar {
    width: 22rem;
    margin: 30px 30px 30px 15px;

    .card-header {
      button {
        width: 100%;

        i {
          margin-top: -4px;
        }
      }
    }
  }

  .main-controls {
    display: flex;
    flex-direction: column;
    flex: 1;

    .card,
    .col-md-6,
    .col-md-12,
    .horizontal-panels {
      display: flex;
    }

    .fullscreen-container {
      height: 100%;
    }
    .large-container {
      flex: 3;
      height: 100%;
    }

    .horizontal-panels {
      flex: 2;
    }
  }

  .card-body.centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .map-container {
    overflow: hidden;
  }
  .small-map-container {
    .lil-gui {
      display: none;
    }
    height: 320px;
  }

}

.modal.show {
  .report-modal {
    &.modal-dialog {
      transform: translate(0, 10%);
    }
  }
}