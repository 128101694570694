@import "../../scss-setup/index";

.landingPageContainer {
  background-image: url("../../img/background_landing_page.png");
  background-repeat: no-repeat;
  background-size: 100%;
  flex: 1;

  .container {
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
