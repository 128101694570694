.flightView {
  flex: 1;
  display: flex;
  overflow: hidden;

  .flightContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    overflow: hidden;


    margin: 0 0 0 115px;

    .title-bar {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 15px;

      h4 {
        margin: 0;
      }
    }
  }


  .flight-toolbar {
    width: 12rem;
    margin: 30px 30px 30px 15px;

    .card-header {
      button {
        width: 100%;

        i {
          margin-top: -4px;
        }
      }
    }
  }


  .main-controls {
    display: flex;
    flex-direction: column;
    flex: 1;

    .card,
    .col-md-6,
    .col-md-12,
    .horizontal-panels {
      flex: 1;
      display:flex;
      height: 400px;
    }
  }

  .card-body.centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user-list {
    color: rgba(255, 255, 255, 0.8);

    .user-list-title{
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      padding: .4rem 0;
    }
    .user-row {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: .4rem 0;
    }
    i {
      margin-right: .1rem;
    }
  }
}
