.DragAndDropUpload {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 260px;
}
label {
  width: 100%;

  box-sizing: border-box;
  height: 100% !important;
}
