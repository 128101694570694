.buildingPlanList {
  margin-top: 3em;

  .buttonEquivalentFiller {
    width: 30px;
  }
}

.toast-custom-class {
  margin-top: 4em;
  margin-right: 2em;
}
