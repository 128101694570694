.protractorContainer {
  height: 100%;
  align-items: center;
  margin-right: 20px;

  .rotationArrowButtons {
    display: flex;
    justify-content: center;
  }

  .protractor {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 280px;
      border-radius: 100%;
    }

    .protractorIMG {
      position: absolute;
      padding-left: 3px;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .protractorPointerIMG {
      position: absolute;
      border-radius: 100%;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .droneFromAbove {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 52px;

      .droneFromAboveIMG {
        width: 200px;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .control-buttons-container {
        position: absolute;
        display: grid;
        gap: 10px;
        flex: 1;

        .grid-container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(3, 1fr);
          gap: 20px;
        }

        .grid-item {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }

        .transparent-button {
          background-color: transparent;

          &.highlight-icon svg {
            color: red;
          }

          svg {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
