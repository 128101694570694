.report-modal {

  .section {
    margin-top: 1rem;
  }
  
  .emphasis {
    font-weight: bold;
  }

  .building-plan-container {
    position: relative;
    width: 0;
    height: 0;

    .building-plan {
      position: absolute;
      width: 1600px;
      height: 1000px;
      top: -1600px;
      left: -1000px;
      display: flex;
    }
  }

  .comment-content {
    word-wrap: break-word;
  }

  a.disabled {
    pointer-events: none;
  }
}